function animate(el, t) {
    if (t) {
        $('html, body').animate({
            scrollTop: $(el).offset().top - 50
        }, 500, function () {
            $('#facebook').addClass('active');
        });
    } else {
        $('html, body').animate({
            scrollTop: $(el).offset().top - 50
        }, 500);
    }
}

function removePopupMsg(el) {
    $(el).parents('.form-group').find('.incorrect-value').remove();
}

function validation(width, obj) {
    var validationStatus;
    var regExp = /^0[0-9]{1,}/g;
    $('#calculator input, #calculator select').each(function () {
        var fieldValue = $(this).val();
        var fieldID = $(this).attr('id');
        var patternValue = regExp.test(fieldValue);

        if (fieldID === 'weight' || fieldID === 'height' || fieldID === 'age') {
            if (fieldValue === '' || patternValue === true) {
                removePopupMsg($(this));
                $(this).parent().before('<span class="incorrect-value">Ungültige Werte</span>');
                validationStatus = 'error';
            } else if (parseInt(fieldValue) <= 0) {
                removePopupMsg($(this));
                $(this).parent().before('<span class="incorrect-value">Der Wert muss größer als 0 sein</span>');
                validationStatus = 'error';
            } else {
                removePopupMsg($(this));
            }
        } else {
            if (fieldValue === '' || patternValue === true || fieldValue < 0) {
                removePopupMsg($(this));
                $(this).parent().before('<span class="incorrect-value">Ungültige Werte</span>');
                validationStatus = 'error';
            } else {
                removePopupMsg($(this));
            }
        }
    });

    if (validationStatus === 'error') {
        //initDataLayer(obj);
        animate('.incorrect-value');
        return false;
    } else {
        if (width > 767) {
            animate('#result', true);
            checkResult();
        } else {
            animate('#result', true);
            checkResult();
        }
        initDataLayer(obj);
        // dtxDataGetSexAndAge(obj);
    }
}

function changeValue(el, name) {
    var value = parseInt(el.parent().find('input').val());
    var min = parseInt(el.parent().find('input').attr('min'));
    var max = parseInt(el.parent().find('input').attr('max'));
    if (name === 'top' && value < max) {
        value++;
    } else if (name === 'bottom' && value > min) {
        value--;
    }
    el.parent().find('input').val(value);
}

function changeSex(el) {
    var imgPath = {
        m: 'img/mannlich-1.png',
        ma: 'img/mannlich.png',
        w: 'img/weiblich-1.png',
        wa: 'img/weiblich.png'
    }
    var idValue;

    if (typeof el === 'object') {
        var className = el.attr('class');
        idValue = el.attr('id');
        if (className !== 'active' && idValue === 'male') {
            el.addClass('active').attr('src', imgPath.ma).next().removeClass('active').attr('src', imgPath.w);
            $('#sex').val('male');
        } else if (className !== 'active' && idValue === 'female') {
            el.addClass('active').attr('src', imgPath.wa).prev().removeClass('active').attr('src', imgPath.m);
            $('#sex').val('female');
        }
    } else if (typeof el === 'string') {
        idValue = el;
        if (idValue === 'male') {
            $('#male').addClass('active').attr('src', imgPath.ma).next().removeClass('active').attr('src', imgPath.w);
        } else if (idValue === 'female') {
            $('#female').addClass('active').attr('src', imgPath.wa).prev().removeClass('active').attr('src', imgPath.m);
        }
    }
}

function checkResult() {
    // Objetosc wypitego piwa [ml]
    var beerVol = parseInt($('#beer').val()) * 500;

    // Objetosc wypitego wina [ml]
    var wineVol = parseInt($('#wine').val()) * 150;

    // Objetosc wypitej wodki [ml]
    var vodkaVol = parseInt($('#vodka').val()) * 50;

    var sexValue = $('#sex').val();
    var weightValue = parseInt($('#weight').val());
    var heightValue = parseInt($('#height').val());
    var ageValue = parseInt($('#age').val());

    var timeValue = parseInt($('#time').val());

    // Wspolczynnik eliminiacji alkoholu z organizmu
    var elimination = {
        ratio1: 0.1, // Osoby pijace rzadko
        ratio2: 0.15, // Osoby pijace przecietnie
        ratio3: 0.2 // Osoby pijace czesto
    };

    // Suma ilosci, wypitego czystego alkoholu [g]
    function sumAlk(b, w, v) {
        var result;
        result = ((b * 0.05) + (w * 0.12) + (v * 0.4)) * 0.79;
        return result;
    }
    var sumalk = sumAlk(beerVol, wineVol, vodkaVol);
    // console.log('Ilosc czystego alkoholu: ' + sumalk);

    // Ilosc plynow ustrojowych w organizmie
    function bodyFluids(s, w, h, a) {
        var result;
        if (s === 'male') {
            result = 2.447 - (0.09156 * a) + (0.1074 * h) + (0.3362 * w);
        } else if (s === 'female') {
            result = -2.097 + (0.1069 * h) + (0.2466 * w);
        }
        return result;
    }
    var bodyfluids = bodyFluids(sexValue, weightValue, heightValue, ageValue);
    // console.log('Ilosc plynow ustrojowych: ' + bodyfluids);

    // Szybkosc eliminacji alkoholu z organizmu w czasie [h]
    function eliminationAlcohol(t) {
        var result;
        result = t * elimination.ratio2;
        return result;
    }
    var eliminationalcohol = eliminationAlcohol(timeValue);
    // console.log('Ile alkoholu mniej po czasie(' + timeValue + 'h): ' + eliminationalcohol);

    // Wyswietlenie wyniku
    function permilRes(s, b, e) {
        var result;
        result = (s / b) * 0.8 - e;
        if (result < 0 || result === 0) {
            result = '0.00';
        } else if (result > 5) {
            result = '>5.00';
        } else {
            result = result.toFixed(2);
        }
        return result;
    }
    var permilres = permilRes(sumalk, bodyfluids, eliminationalcohol);
    //console.log('Promile: ' + permilres);
    $('#content').text(permilres + ' \u2030');

    // Pozostały czas do wytrzezwienia
    function remainingTime() {
        var time;
        if (parseFloat(permilres) < 5) {
            time = '~ ' + Math.ceil(parseFloat(permilres) / parseFloat(elimination.ratio2)) + ' Stunden';
        } else {
            time = 'Tödliche Dosis';
        }
        return time;
    }
    var remainingtime = remainingTime();
    // console.log('Pozostaly czas: ' + remainingtime);
    $('#sobriety').text(remainingtime);
}

// Analityka Google
function initDataLayer(obj) {

    // Waga
    var weightRange = [{
        min: 0,
        max: 60
    }, {
        min: 61,
        max: 80
    }, {
        min: 81,
        max: 100
    }, {
        min: 101,
        max: '\u221E'
    }];
    var weight = obj[1].value;
    for (var a = 0; a < weightRange.length; a++) {
        if (weight >= weightRange[weightRange.length - 1].min) {
            weight = weightRange[weightRange.length - 1].min + '-' + weightRange[weightRange.length - 1].max;
        } else if (weight >= weightRange[a].min && weight <= weightRange[a].max) {
            weight = weightRange[a].min + '-' + weightRange[a].max;
        }
    }

    // Wzrost
    var heightRange = [{
        min: 0,
        max: 150
    }, {
        min: 151,
        max: 180
    }, {
        min: 181,
        max: 190
    }, {
        min: 191,
        max: 200
    }, {
        min: 201,
        max: '\u221E'
    }];
    var height = obj[2].value;
    for (var b = 0; b < heightRange.length; b++) {
        if (height >= heightRange[heightRange.length - 1].min) {
            height = heightRange[heightRange.length - 1].min + '-' + heightRange[heightRange.length - 1].max;
        } else if (height >= heightRange[b].min && height <= heightRange[b].max) {
            height = heightRange[b].min + '-' + heightRange[b].max;
        }
    }

    // Wiek
    var ageRange = [{
        min: 0,
        max: 18
    }, {
        min: 19,
        max: 24
    }, {
        min: 25,
        max: 34
    }, {
        min: 35,
        max: 44
    }, {
        min: 45,
        max: 54
    }, {
        min: 55,
        max: '\u221E'
    }];
    var age = obj[3].value;
    for (var c = 0; c < ageRange.length; c++) {
        if (age >= ageRange[ageRange.length - 1].min) {
            age = ageRange[ageRange.length - 1].min + '-' + ageRange[ageRange.length - 1].max;
        } else if (age >= ageRange[c].min && age <= ageRange[c].max) {
            age = ageRange[c].min + '-' + ageRange[c].max;
        }
    }

    // Plec
    var sex;
    if (obj[0].value === 'male') {
        sex = 'Mężczyzna';
    } else if (obj[0].value === 'female') {
        sex = 'Kobieta';
    }

    // Wynik pomiaru
    var resultRange = [{
        min: 0.00,
        max: 0.20
    }, {
        min: 0.21,
        max: 0.50
    }, {
        min: 0.51,
        max: 0.99
    }, {
        min: 1.00,
        max: 1.99
    }, {
        min: 2.00,
        max: 3.99
    }, {
        min: 4.00,
        max: '\u221E'
    }];
    var result = $('#content').text();
    for (var i = 0; i < resultRange.length; i++) {
        if (result >= resultRange[resultRange.length - 1].min) {
            result = (resultRange[resultRange.length - 1].min).toFixed(2) + '-' + resultRange[resultRange.length - 1].max;
        } else if (result >= resultRange[i].min && result <= resultRange[i].max) {
            result = (resultRange[i].min).toFixed(2) + '-' + (resultRange[i].max).toFixed(2);
        }
    }

    // Przekazanie literału obiektowego do tablicy (dataLayer)
    dataLayer.push({
        'Waga': weight, // Waga
        'Wzrost': height, // Wzrost
        'Wiek': age, // Wiek
        'Płeć': sex, // Plec
        'Wynik': result, // Wynik pomiaru
        'event': 'WynikBadania'
    });
}

// Data Exchanger
/*var mt_sex = '';
var mt_age = '';

function dtxDataGetSexAndAge(obj) {
    if (obj[7].value === 'male') {
        mt_sex = '1';
    } else if (obj[7].value === 'female') {
        mt_sex = '0';
    }
    mt_age = new Date().getFullYear() - parseInt(obj[6].value) + '-01-01';
}

function dtx_data() {
    return {
        mt_pid: 6139,
        mt_s: mt_sex,
        mt_a: mt_age
    };
}*/
// End Data Exchanger

$(document).ready(function () {
    $('#calculator').on('submit', function (event) {
        event.preventDefault();
        var obj = event.target;
        validation($(window).width(), obj);
    });

    $('#calculator input').on('focus', function (event) {
        var el = '#' + event.target.name;
        removePopupMsg(el);
        $('#facebook').removeClass('active');
        // validation();
    });

    changeSex($('#sex').val());

    $('#selectSex > img').on('click', function () {
        var $el = $(this);
        changeSex($el);
    });

    $('#calculator input, #sex').on('change', function (event) {
        var el = '#' + event.target.name;
        removePopupMsg(el);
        $('#facebook').removeClass('active');
        if (event.target.id === 'sex') {
            var $el = $(this).val();
            changeSex($el);
        }
        // validation();
    });

    $('[type="reset"]').on('click', function () {
        $('.incorrect-value').remove();
        $('#content').text('0.00 \u2030');
        $('#sobriety').text('--');
        animate('#promillerechner');
        $('#facebook').removeClass('active');
        changeSex('male');
    });

    $('#calculator .controls > span').on('click', function (event) {
        var className = event.target.className;
        var str = className.substring(className.lastIndexOf('-') + 1, className.length);
        removePopupMsg($(this));
        changeValue($(this), str);
    });
});