(function ($) {
    $(document).ready(function () {
        function autoheight() {
            var windowWidth = $(window).width();
            $('.item > p').css('height', 'auto');
            if (windowWidth >= 992) {
                var el = [];
                $('.alkoholtester > div').each(function () {
                    el.push('#' + $(this).attr('id') + ' .item > p');
                });
                for (var i = 0; i < el.length; i++) {
                    var elHeight = 0;
                    $(el[i]).each(function () {
                        if (elHeight < $(this).outerHeight()) {
                            elHeight = $(this).outerHeight();
                        }
                    });
                    $(el[i]).css('height', elHeight);
                }
            }
        }

        function initProducts(data) {
            for (var i = 0; i < data.products.length; i++) {
                var html = '';
                html += '<div class="col-md-4">';
                if (data.products[i].recommended === true) {
                    html += '<div class="item recommended">';
                } else {
                    html += '<div class="item">';
                }
                html += '<img src="' + data.products[i].image + '" alt="' + data.products[i].name + '">';
                html += '<h3>' + data.products[i].name + '</h3>';
                html += '<p>' + data.products[i].description + '</p>';
                html += '<a class="btn btn-default" href="' + data.products[i].url + '">Jetzt kaufen</a>';
                html += '</div>';
                html += '</div>';
                $('#' + data.products[i].category).append(html);
            }
            autoheight();
        }

        function error() {
            $('#elektrochemische, #halbleiter, #professionelle').html('<p class="loading"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Laden...</span></p>');
        }

        if (window.location.pathname === '/die-besten-alkoholtester.html') {
            $.getJSON("data/products.json", function (data) {
                initProducts(data);
            }).fail(function () {
                error();
            });

            $(window).on('resize', function () {
                autoheight();
            });
        }
    });
} (jQuery));