(function ($) {
    $(document).ready(function () {
        var windowHeight;
        var $windowScrollTop = $(window).scrollTop();
        var elements = [{
            el: 'body > header .jmod',
            dest: 'body > header .jmod'
        }, {
            el: '.charts',
            dest: '.collected-information [class^="bar-chart"]'
        }];

        function calcWindowHeight() {
            windowHeight = $(window).height();
        }

        function activator(wh, st) {
            for (var i = 0; i < elements.length; i++) {
                if ($(elements[i].el).length > 0) {
                    var elOffsettop = $(elements[i].el).offset().top;
                    var pos = elOffsettop - wh;
                    if (st >= pos) {
                        $(elements[i].dest).addClass('active');
                    }
                }
            }
        }
        calcWindowHeight();
        activator(windowHeight, $windowScrollTop);
        $(window).on('resize', calcWindowHeight);
        $(window).on('scroll', function (event) {
            activator(windowHeight, event.target.scrollingElement.scrollTop);
        });
    });
}(jQuery));